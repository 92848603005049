<template>
  <div>
    <v-card class="pa-4" max-width="620" min-height="300">
      <v-card-title class="panel-title"> Edit Topic </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="topicModel">
        <v-form ref="topicForm" v-model="valid" @submit.prevent="submit" action>
          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-select
                autofocus
                v-model="category"
                :items="categories"
                :rules="selectRules"
                item-text="name"
                item-value="_id"
                label="Select categories"
                placeholder="Select categories"
                outlined
                persistent-hint
                return-object
                single-line
              ></v-select>
              <v-select
                v-model="course"
                :items="courses"
                :rules="selectRules"
                item-text="title"
                item-value="_id"
                label="Select course"
                placeholder="Select course"
                outlined
                persistent-hint
                return-object
                single-line
              ></v-select>
              <v-text-field
                label="Title"
                v-model="topicModel.title"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-switch
                v-if="topicModel._id"
                label="Edit Cover Photo"
                v-model="editFiles"
              ></v-switch>

              <v-file-input
                v-if="editFiles || !topicModel._id"
                outlined
                show-size
                truncate-length="18"
                :disabled="loading"
                :rules="photoRules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                label="Cover image"
                v-model="topicModel.coverImg"
              ></v-file-input>
              <v-textarea
                label="Description"
                v-model="topicModel.description"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-n4"></v-divider>

          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mt-4">
              <v-btn
                :loading="loading"
                x-large
                color="primary"
                @click="submit"
                block
                >Save</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mr-0 mt-4">
              <v-btn @click="$emit('close-dialog')" x-large outlined block
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  props: ["game"],
  computed: {
    ...mapGetters(["formartCurrency", "formatDateMed"]),
  },
  created() {},
  methods: {
    ...mapActions("api", ["httpGet", "httpPostMP", "httpPost"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },

    validate: function () {
      this.$refs.topicForm.validate();
    },
    saveProduct: async function () {
      console.log("posting===", this.topicModel);
      this.topicModel.courseId = this.course._id;
      this.topicModel.courseName = this.course.title;
      this.topicModel.categoryId = this.category._id;
      this.topicModel.categoryName = this.category.name;

      let formData = new FormData();
      formData.append("coverImg", this.topicModel.coverImg);
      formData.append("courseId", this.topicModel.courseId);
      formData.append("title", this.topicModel.title);
      formData.append("description", this.topicModel.description);
      formData.append("courseName", this.topicModel.courseName);
      formData.append("categoryId", this.topicModel.categoryId);
      formData.append("categoryName", this.topicModel.categoryName);
      if (this.topicModel._id) {
        formData.append("_id", this.topicModel._id);
      }

      // console.log(this.topicModel);
      let params = await this.httpPostMP({
        withAuth: true,
        url: "/admin/save_topic",
        data: formData,
      });
      return params.data;
    },

    updateProduct: async function () {
      this.topicModel.courseId = this.course._id;
      this.topicModel.courseName = this.course.title;
      this.topicModel.categoryId = this.category._id;
      this.topicModel.categoryName = this.category.name;
      // console.log(this.topicModel);
      let params = await this.httpPost({
        withAuth: true,
        url: "/admin/update_topic",
        data: this.topicModel,
      });
      console.log("update2===", this.topicModel);
      return params.data;
    },

    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with topic data",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            if (!this.editFiles && this.topicModel._id) {
              await this.updateProduct();
            } else {
              await this.saveProduct();
            }
            this.loading = false;
            this.showMessage({ text: "topic saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            console.error(error);
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  watch: {
    async category(val) {
      this.loading = true;
      try {
        let params = await this.httpGet({
          withAuth: true,
          url: "/admin/get_cat_courses",
          data: { id: val._id },
        });
        this.loading = false;
        this.courses = params.data;
        // console.log("--->>", val, params.data);
      } catch (error) {
        this.loading = false;
        let errMsg = config.errors.generic;
        this.showMessage({
          text: errMsg,
          error: true,
        });
      }
    },
    async topicModel(val) {
      if (val.courseId) {
        this.course = { _id: val.courseId, name: val.courseName };
        this.category = { _id: val.categoryId, name: val.categoryName };
      }
      this.loading = true;
      try {
        let params = await this.httpGet({
          withAuth: true,
          url: "/admin/get_all_categories",
          data: {},
        });
        this.loading = false;
        this.categories = params.data;
        // console.log("--->>", val, params.data);
      } catch (error) {
        this.loading = false;
        let errMsg = config.errors.generic;
        this.showMessage({
          text: errMsg,
          error: true,
        });
      }
    },
  },
  data() {
    return {
      editFiles: false,
      search: "",
      categories: [],
      category: { name: "", _id: "" },
      courses: [],
      course: { name: "", _id: "" },
      topicModel: {
        description: "",
        course: "",
        courseId: "",
        category: "",
        categoryId: "",
        title: "",
      },
      photoRules: [
        (v) => {
          if (v) {
            if (v.size < 2000000) {
              return true;
            } else {
              return "Photo size should be less than 2 MB!";
            }
          } else {
            return "This Field is required";
          }
        },
      ],
      menu: false,
      loading: false,
      busy: false,
      nameRules: [(v) => !!v || "This Field is required"],
      selectRules: [(v) => !!v._id || "This Field is required"],
      valid: false,
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

